import { createApp } from 'vue';
import { isObject, wait } from 'Utils';
import {
  MESSAGE_TYPES,
  MessageOptions,
  MessageOptionsNormalized,
  MessageText,
} from './types';

let isComponentMounted = false;

async function mountComponent() {
  isComponentMounted = true;
  const { default: TheMessage } = await import(/* webpackChunkName: "TheMessage" */ './components/TheMessage.vue');
  const container = document.createElement('div');
  container.id = 'message-container';
  const appElement = document.getElementById('app');
  if (!appElement) return;
  appElement.appendChild(container);
  createApp(TheMessage).mount(container);
  // wait to be sure
  await wait(500);
}

async function addItem(text: MessageText, options: MessageOptionsNormalized) {
  if (!isComponentMounted) mountComponent();
  const { useMessageStore } = await import(/* webpackChunkName: "messageStore" */'./messageStore');
  const { addMessage } = useMessageStore();
  return addMessage(text, options);
}

async function removeItem(itemId: string) {
  const { useMessageStore } = await import('./messageStore');
  const { removeMessage } = useMessageStore();
  removeMessage(itemId);
}

/**
 * Normalize options
 * @param options
 * @returns {{timeout: number}}
 */
function getNormalizedOptions(options: MessageOptions): MessageOptionsNormalized {
  if (options === false) return { timeout: 0 };
  if (typeof options === 'number') return { timeout: options };
  return isObject(options) ? options : {};
}

/**
 * Show success message
 * @param text {String} - text for message
 * @param options {MessageOptions} - get options as object, false|number as timeout
 * @returns {String} - return message id
 */
export async function success(text: MessageText, options: MessageOptions = {}): Promise<string> {
  const normalizedOptions = getNormalizedOptions(options);
  return addItem(text, { ...normalizedOptions, type: MESSAGE_TYPES.SUCCESS });
}

/**
 * Show error toaster message
 * @param text {String} - text for message
 * @param options {Object|Boolean} - get options as object, false|number as timeout
 * @returns {Number} - return toaster id
 */
export function error(text: MessageText, options: MessageOptions = {}): Promise<string> {
  const normalizedOptions = getNormalizedOptions(options);
  return addItem(text, { ...normalizedOptions, type: MESSAGE_TYPES.ERROR });
}

/**
 * Show info toaster message
 * @param text {String|Array} - text for message, String as simple message,
 *   array with 2 string as simple and more text
 * @param options {Object|Boolean|Number} - get options as object, false|number as timeout
 * @returns {Number} - return toaster id
 */
export function info(text: MessageText, options: MessageOptions = {}): Promise<string> {
  const normalizedOptions = getNormalizedOptions(options);
  return addItem(text, { ...normalizedOptions, type: MESSAGE_TYPES.INFO });
}

/**
 * Show warning toaster message
 * @param text {String|Array} - text for message, String as simple message,
 *   array with 2 string as simple and more text
 * @param options {Object|Boolean|Number} - get options as object, false|number as timeout
 * @returns {Number} - return toaster id
 */
export function warning(text: MessageText, options: MessageOptions = {}): Promise<string> {
  const normalizedOptions = getNormalizedOptions(options);
  return addItem(text, { ...normalizedOptions, type: MESSAGE_TYPES.WARNING });
}

/**
 * Show light warning toaster message
 * @param text {String|Array} - text for message, String as simple message,
 *   array with 2 string as simple and more text
 * @param options {Object|Boolean|Number} - get options as object, false|number as timeout
 * @returns {Number} - return toaster id
 */
export function warningLight(text: MessageText, options: MessageOptions = {}): Promise<string> {
  const normalizedOptions = getNormalizedOptions(options);
  return addItem(text, { ...normalizedOptions, type: MESSAGE_TYPES.WARNING_LIGHT });
}
/**
 * Show toaster message
 * @param text {String|Array} - text for message, String as simple message,
 * @param options {Object|Boolean|Number} - get options as object, false|number as timeout
 * @returns {*|Number}
 */
export function message(text: MessageText, options: MessageOptions = {}): Promise<string> {
  const normalizedOptions = getNormalizedOptions(options);
  return addItem(text, { ...normalizedOptions });
}

export function hide(itemId: string): void {
  removeItem(itemId);
}

export const messageSuccess = success;
export const messageError = error;
export const messageInfo = info;
export const messageHide = hide;
export const messageWarning = warning;
export const messageWarningLight = warningLight;
