import { Ref } from 'vue';

export enum MESSAGE_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  WARNING_LIGHT = 'warning_light'
}

export type MessageText = string|[string, string?];

export type MessageOptionsNormalized = {
  timeout?: number,
  type?: MESSAGE_TYPES,
  code?: string,
  onUserClose?: () => void,
};

export type MessageOptions = false | number | MessageOptionsNormalized;

export type MessageItem = MessageOptionsNormalized & {
  text: MessageText;
  created: number;
  id: string;
};

export interface IMessageStore {
  messageList: Readonly<Ref<MessageItem[]>>,
  addMessage: (text: MessageText, options: MessageOptionsNormalized) => string,
  removeMessage: (messageId: string) => void,
}

export interface IMessageMap {
  [key: string]:{
    icon:string,
    class:string,
    alt:string
  }
}

export interface IMessageAction {
  text: string
  onClick: () => void
}
