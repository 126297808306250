import { RouteLocation } from 'vue-router';
import { useLogin } from 'Store';
import { suppressUser } from '@/services/api/user';
import { messageSuccess, messageWarningLight } from 'Helpers/message';
import CONFIG from 'Config';

import {
  LINK_CLICKED,
  BEGIN_INVESTING,
  GET_STARTED,
  EXPLAINER_WATCH_END,
  EXPLAINER_WATCH_START,
  ADVISOR_REQUEST_INTRODUCTION,
  ADVISOR_SCHEDULE_CALL,
  VIEWED_REALIZED_TRACK_RECORD,
} from './events';
import { getUserProfileParams, getUniversalParams } from './params';

export function triggerEvent(event: string, data = {}) {
  const baseParams = getUniversalParams();
  window.analytics?.track(event, { ...data, ...baseParams });
}

export function triggerPage(event: string) {
  const baseParams = getUniversalParams();
  window.analytics?.page(event, { ...baseParams });
}

export function triggerExplainerWatchStart(): void {
  triggerEvent(EXPLAINER_WATCH_START);
}

export function triggerExplainerWatchEnd(data: { [key: string]: string|number|boolean }): void {
  triggerEvent(EXPLAINER_WATCH_END, data);
}

export function triggerGetStarted(email: string): void {
  triggerEvent(GET_STARTED, { email });
}

export function triggerBeginInvesting(): void {
  triggerEvent(BEGIN_INVESTING);
}

export function triggerLinkClicked(data = {}): void {
  triggerEvent(LINK_CLICKED, data);
}

export function triggerAdvisorButtonClick(data:{[key:string]: unknown}): void {
  triggerEvent(ADVISOR_REQUEST_INTRODUCTION, data);
}

export function triggerAdvisorScheduleCall(): void {
  triggerEvent(ADVISOR_SCHEDULE_CALL);
}

export function triggerViewedRealizedTrackRecord(): void {
  triggerEvent(VIEWED_REALIZED_TRACK_RECORD, getUserProfileParams());
}

export async function handleOptOut(): Promise<void> {
  const { isLoggedIn } = useLogin();
  if (isLoggedIn.value) {
    suppressUser();
    messageSuccess('Successfully opt-out');
  } else {
    // Cant opt-out
    messageWarningLight('Please login to your account to opt-out of online tracking. Click here to <a href="/sign-in?redirect=/company/privacy#additional-information-for-users-located-in-California">Sign In</a> or <a href="/sign-up?redirect=/company/privacy#additional-information-for-users-located-in-California">Sign Up</a>');
  }
}

function triggerSiftPageView(): void {
  const _sift = window._sift = window._sift || []; // eslint-disable-line
  _sift.push(['_setAccount', CONFIG.siftAccount]);
  // if (store.getters['login/isLoggedIn']) {
  //   const { email } = store.getters['profile/profile'];
  //   _sift.push(['_setUserId', email]);
  // }
  _sift.push(['_trackPageview']);
}

function triggerHSPageView(route: RouteLocation): void {
  const _hsq = window._hsq = window._hsq || []; // eslint-disable-line
  // if (store.getters['login/isLoggedIn']) {
  //   const { email } = store.getters['profile/profile'];
  //   _hsq.push(['identify', { email }]);
  // } else {
  _hsq.push(['identify']);
  // }
  _hsq.push(['setPath', route.fullPath]);
  _hsq.push(['trackPageView']);
}

export function onPageResolved(route: RouteLocation): void {
  setTimeout(() => {
    triggerPage(route.path);
    triggerSiftPageView();
    triggerHSPageView(route);
  }, 1500);
}
